<style>
@media (max-width: 1024px) {
  .sm-spacer {
    margin-top: .5rem;
  }
}
</style>
<script>
import {VclBulletList, VclCode, VclFacebook, VclList} from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";
import TimeAgo from "vue2-timeago";
import {Game} from "@/enums";
import {HalfCircleSpinner} from "epic-spinners";
import {get_acsrf_token} from "@/methods";

export default {
  props: ['cftools_id', 'options'],
  components: {
    // CopyableText,
    VclFacebook,
    VclCode,
    VclList,
    VclBulletList,
    // TimeAgo,
    // HalfCircleSpinner
	  IntelligenceNav: () => import('@/components/lazy/profile/components/intelligence/Nav.vue'),
  },
  computed: {
    ...mapGetters([
      'getUILanguage',
      'getDTLocale'
    ]),
  },
  methods: {
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " d";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " h";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " m";
      }
      return Math.floor(seconds) + " s";
    },
    currentDateTime(date) {
      if(!date || date === null) date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async getData() {
	    try {
		    let url = new URL(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/intelligence/status`);

		    let response = await fetch(url, {
			    method: 'GET',
			    credentials: 'include'
		    });

		    if(response.ok) {
			    let data = await response.json();
			    this.player = data.player;
			    this.$nextTick(() => {
				    this.ready = true;
			    });
		    } else {
			    if (response.status === 403) {
				    this.upsell = true;
						return;
			    }
			    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
		    }

	    } catch (error) {
		    this.handleError(error);
	    }
    },
    overridePersona(personaName, avatar) {
      this.$parent.$emit('overridePersona', personaName, avatar);
    },
    timeTrick(seconds) {
      return new Date(Date.now() - 1000 * seconds);
    },
  },
  created() {

  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
	    upsell: false,
      error: false,
	    player: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <!-- Content row one -->
          <div class="row" v-if="player">
	          <div class="col-lg-12">
		          <div class="row mb-0 pb-0">
			          <div class="col-lg-3 col-sm-6">
				          <div class="card mini-stats-wid mb-0 pb-0">
					          <div class="card-body" style="height: 5.5rem;">
						          <div class="d-flex flex-wrap">
							          <div class="me-3">
								          <p class="text-muted mb-2 text-uppercase">
									          {{ $t('profile.intelligence.general.created') }}
								          </p>
								          <h5 class="mb-0">
									          {{ $d(parseDate(player.created_at), 'date', getDTLocale()) }}
								          </h5>
							          </div>
						          </div>
					          </div>
				          </div>
			          </div>
			          <div class="col-lg-3 col-sm-6">
				          <div class="card mini-stats-wid mb-0 pb-0">
					          <div class="card-body" style="height: 5.5rem;">
						          <div class="d-flex flex-wrap">
							          <div class="me-3">
								          <p class="text-muted mb-2 text-uppercase">
									          {{ $t('profile.intelligence.general.updated') }}
								          </p>
								          <h5 class="mb-0">
									          {{ $d(parseDate(player.updated_at), 'date', getDTLocale()) }}
								          </h5>
							          </div>
						          </div>
					          </div>
				          </div>
			          </div>
			          <div class="col-lg-3 col-sm-12">
				          <div class="card mini-stats-wid mb-0 pb-0">
					          <div class="card-body" style="height: 5.5rem;">
						          <div class="d-flex flex-wrap">
							          <div class="me-3">
								          <p class="text-muted mb-2 text-uppercase">
									          {{ $t('profile.intelligence.general.playtime') }}
								          </p>
								          <h5 class="mb-0">
									          {{ timeSince(timeTrick(player.playtime)) }}
									          <small class="text-muted text-small">
										          {{ $n(player.sessions) }} {{ $t('profile.intelligence.general.sessions') }}
									          </small>
								          </h5>
							          </div>
						          </div>
					          </div>
				          </div>
			          </div>
			          <div class="col-lg-3 col-sm-12">
				          <div class="card mini-stats-wid mb-0 pb-0">
					          <div class="card-body" style="height: 5.5rem;">
						          <div class="d-flex flex-wrap">
							          <div class="me-3">
								          <p class="text-muted mb-2 text-uppercase">
									          {{ $t('profile.intelligence.general.active_titles') }}
								          </p>
								          <h5 class="mb-0">
									          <img
											          alt="game title logo"
											          class=""
											          :src="'/' +gameIcon(1)"
											          height="22"
											          width="22"
											          v-if="player.titles.includes('dayz')"
									          >
								          </h5>
							          </div>
						          </div>
					          </div>
				          </div>
			          </div>
		          </div>
	          </div>
          </div>
          <div class="row mt-4" v-else>
	          <div class="col justify-content-center">
		          <div  class="info-component text-center align-middle mt-auto mb-auto">
			          <div class="info-component-space">
				          <i class="fad fa-chart-network text-warning info-component-icon"></i>
			          </div>
			          <div class="row" style="margin-top: 20px;">
				          <div class="col-lg-12">
					          <h3 v-b-tooltip.hover>{{ $t('profile.intelligence.none.title') }}</h3>
					          <h5 class="text-muted mb-2">{{ $t('profile.intelligence.none.text') }}</h5>
				          </div>
			          </div>
		          </div>
	          </div>
          </div>
	        <IntelligenceNav :cftools_id="cftools_id" v-if="player"/>
        </div>
      </div>
    </template>
	  <template v-else-if="upsell">
		  <div class="row mt-4">
			  <div class="col justify-content-center">
				  <div  class="info-component text-center align-middle mt-auto mb-auto">
					  <div class="info-component-space">
						  <i class="fad fa-chart-network text-info info-component-icon"></i>
					  </div>
					  <div class="row" style="margin-top: 20px;">
						  <div class="col-lg-12">
							  <h3 v-b-tooltip.hover>{{ $t('profile.intelligence.upsell.title') }}</h3>
							  <h5 class="text-muted mb-2">{{ $t('profile.intelligence.upsell.text', {plan: 'Community'}) }}</h5>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
